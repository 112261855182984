<template>
  <div
    class="store px-8 py-10 container trigger-update"
    style="max-width: 1150px;margin:0 auto;"
  >
    <TitleComponent :title="`Shop ${selectedCategory}`" size="large" />
    <div
      class="flex flex-wrap pb-4 mb-4 border-b-2 border-gray-400"
      v-if="selectedCategory"
    >
      <div class="w-full text-left">
        <button
          v-if="!detailView"
          @click="$router.push({ path: '/shop' })"
          class="bg-transparent hover:bg-green-500 text-green-700 font-semibold hover:text-white py-2 px-4 border border-green-500 hover:border-transparent rounded"
        >
          Go Back To Select Categories
        </button>
        <button
          v-if="detailView"
          @click="
            detailView = null;
            detailImageIndex = 0;
            detailCartAddAmount = 1;
            selectedOption = '';
            latestAdd = null;
          "
          class="bg-transparent hover:bg-green-500 text-green-700 font-semibold hover:text-white py-2 px-4 border border-green-500 hover:border-transparent rounded"
        >
          Go Back To List
        </button>
      </div>
    </div>
    <transition name="fade">
      <div
        class="flex flex-wrap pb-2 mb-4 border-b-2 border-gray-400"
        v-if="latestAdd && !hide"
      >
        <div class="w-full sm:w-3/4 text-left">
          <Paragraph>
            {{
              `"${latestAdd.amount > 1 ? `${latestAdd.amount} x ` : ""}${
                latestAdd.name
              }" has been added to your cart.`
            }}
          </Paragraph>
        </div>
        <div class="w-full sm:w-1/4 text-left">
          <router-link to="/cart" class="link text-sm">View Cart</router-link>
        </div>
      </div>
    </transition>
    <div v-if="!selectedCategory" class="flex flex-wrap">
      <div class="w-full sm:w-1/2 sm:pr-2 md:w-1/4 mb-6 md:pr-3 cursor-pointer">
        <div
          @click="updateCategory"
          data-category="abl"
          class="max-w-sm rounded overflow-hidden shadow-lg p-4 text-left"
        >
          <img src="@/assets/Shop/wheel-plate-min.png" />

          <h2>ABL Friction Parts</h2>
        </div>
      </div>
      <div class="w-full sm:w-1/2 sm:pr-2 md:w-1/4 mb-6 md:pr-3 cursor-pointer">
        <div
          @click="updateCategory"
          data-category="mbom"
          class="max-w-sm rounded overflow-hidden shadow-lg p-4 text-left"
        >
          <img src="@/assets/Shop/MBOMinsertanvil-min.png" />

          <h2>MBOM/Type 12 Impact Parts</h2>
        </div>
      </div>
      <div class="w-full sm:w-1/2 sm:pr-2 md:w-1/4 mb-6 md:pr-3 cursor-pointer">
        <div
          @click="updateCategory"
          data-category="containers"
          class="max-w-sm rounded overflow-hidden shadow-lg p-4 text-left"
        >
          <img src="@/assets/Shop/containercategory-min.png" />

          <h2>Shipping Containers</h2>
        </div>
      </div>
      <div class="w-full sm:w-1/2 sm:pr-2 md:w-1/4 mb-6 md:pr-3 cursor-pointer">
        <div
          @click="updateCategory"
          data-category="conductive"
          class="max-w-sm rounded overflow-hidden shadow-lg p-4 text-left"
        >
          <img src="@/assets/Resources/Blog/Lo-ohm-min.png" />

          <h2>Lo-Ohm Conductive Plastic Tools</h2>
        </div>
      </div>
      <div class="w-full sm:w-1/2 sm:pr-2 md:w-1/4 mb-6 md:pr-3 cursor-pointer">
        <div
          @click="updateCategory"
          data-category="koenen"
          class="max-w-sm rounded overflow-hidden shadow-lg p-4 text-left"
        >
          <img src="@/assets/Shop/koenentube.png" />

          <h2>Koenen Tubes</h2>
        </div>
      </div>
    </div>
    <div v-if="selectedCategory && !detailView" class="flex flex-wrap">
      <div
        v-for="item in catalogList"
        :key="item.ID"
        class="w-full sm:w-1/2 sm:pr-2 md:w-1/4 mb-6 md:pr-3"
      >
        <div class="max-w-sm rounded overflow-hidden shadow-lg p-4 text-left">
          <img
            @click="setDetailView(item)"
            class="cursor-pointer"
            :src="require(`@/assets/Shop/${item.Images[0]}`)"
          />
          <div class="border-t-2 pt-4 border-gray-400">
            <h2 @click="setDetailView(item)" class="link">{{ item.Name }}</h2>
            <h2 v-if="item.Price" class="grey">{{ item.Price }}</h2>
            <h2 style="color:#aa222c;" v-if="item['Regular price']">
              {{
                item["Regular price"].toLocaleString("en-US", {
                  style: "currency",
                  currency: "USD"
                })
              }}
            </h2>
          </div>
        </div>
      </div>
    </div>
    <div v-if="detailView" class="flex flex-wrap">
      <div v-if="!selectedOption" class="w-full sm:w-1/2 pr-2">
        <img
          :src="require(`@/assets/Shop/${detailView.Images[detailImageIndex]}`)"
        />
        <div class="flex flex-wrap">
          <div
            v-for="(image, i) in detailView.Images"
            :key="i"
            @click="detailImageIndex = i"
            class="w-1/3 sm:w-1/4 border-double border-4 border-gray-400"
            :class="{ 'border-green-400': i === detailImageIndex }"
          >
            <img
              class="cursor-pointer"
              :src="require(`@/assets/Shop/${image}`)"
            />
          </div>
        </div>
      </div>
      <div v-if="selectedOption" class="w-full sm:w-1/2 pr-2">
        <div v-if="selectedOption.Images">
          <img
            :src="
              require(`@/assets/Shop/${selectedOption.Images[detailImageIndex]}`)
            "
          />
          <div class="flex flex-wrap">
            <div
              v-for="(image, i) in selectedOption.Images"
              :key="i"
              @click="detailImageIndex = i"
              class="w-1/3 sm:w-1/4 border-double border-4 border-gray-400"
              :class="{ 'border-green-400': i === detailImageIndex }"
            >
              <img
                class="cursor-pointer"
                :src="require(`@/assets/Shop/${image}`)"
              />
            </div>
          </div>
        </div>
      </div>
      <div class="w-full sm:w-1/2">
        <div class="pt-8 text-left">
          <h2 style="color:#aa222c;">{{ detailView.Name }}</h2>
          <h2 v-if="!selectedOption && detailView.Price" class="grey">
            {{ detailView.Price }}
          </h2>
          <h2 style="color:#aa222c;" v-if="detailView['Regular price']">
            {{
              detailView["Regular price"].toLocaleString("en-US", {
                style: "currency",
                currency: "USD"
              })
            }}
          </h2>
          <h2 style="color:#aa222c;" v-if="selectedOption['Regular price']">
            {{
              selectedOption["Regular price"].toLocaleString("en-US", {
                style: "currency",
                currency: "USD"
              })
            }}
          </h2>
          <transition name="fade">
            <div
              class="border-t-2 border-gray-400 mt-4 pb-4 pt-8"
              v-if="detailView['Regular price'] && !hide"
            >
              <div class="inline-flex">
                <button
                  @click="changeAmount('subtract')"
                  class="bg-gray-300 hover:bg-gray-400 text-gray-800 font-bold py-2 px-4 rounded-l focus:outline-none"
                >
                  -
                </button>
                <input
                  v-model="detailCartAddAmount"
                  class="appearance-none border text-center text-gray-700 focus:outline-none w-10"
                  type="text"
                />
                <button
                  @click="changeAmount('add')"
                  class="bg-gray-300 hover:bg-gray-400 text-gray-800 font-bold py-2 px-4 rounded-r focus:outline-none"
                >
                  +
                </button>
                <button
                  @click="addToCart(detailView)"
                  class="bg-green-500 hover:bg-green-700 text-white font-bold ml-4 py-2 px-4"
                >
                  Add To Cart
                </button>
              </div>
            </div>
          </transition>
          <div
            class="border-t-2 border-gray-400 mt-4 pb-4 pt-8"
            v-if="detailView.Items"
          >
            <select
              v-model="selectedOption"
              class="block w-full bg-gray-200 border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
              id="grid-state"
            >
              <option value="">Choose an option</option>
              <option
                :value="item"
                v-for="(item, i) in detailView.Items"
                :key="i"
              >
                {{ item.Name }}
              </option>
            </select>
            <transition name="fade">
              <div
                class="border-t-2 border-gray-400 mt-4 pb-4 pt-8"
                v-if="selectedOption && !hide"
              >
                <div class="inline-flex">
                  <button
                    @click="changeAmount('subtract')"
                    class="bg-gray-300 hover:bg-gray-400 text-gray-800 font-bold py-2 px-4 rounded-l focus:outline-none"
                  >
                    -
                  </button>
                  <input
                    v-model="detailCartAddAmount"
                    class="appearance-none border text-center text-gray-700 focus:outline-none w-10"
                    type="text"
                  />
                  <button
                    @click="changeAmount('add')"
                    class="bg-gray-300 hover:bg-gray-400 text-gray-800 font-bold py-2 px-4 rounded-r focus:outline-none"
                  >
                    +
                  </button>
                  <button
                    @click="addToCart(selectedOption)"
                    class="bg-green-500 hover:bg-green-700 text-white font-bold ml-4 py-2 px-4"
                  >
                    Add To Cart
                  </button>
                </div>
              </div>
            </transition>
            <Paragraph
              v-if="selectedOption && selectedOption['Short description']"
            >
              <div v-html="selectedOption['Short description']"></div>
            </Paragraph>
            <Paragraph
              class="mt-4"
              v-if="selectedOption && selectedOption['Description']"
            >
              <div v-html="selectedOption['Description']"></div>
            </Paragraph>
          </div>
          <h2 class="grey border-t-2 border-gray-400 mt-4 pt-4">
            SKU: {{ detailView.SKU }}
          </h2>
          <Paragraph>
            <div v-html="detailView['Short description']"></div>
          </Paragraph>
          <Paragraph class="mt-4" v-if="detailView['Description']">
            <div v-html="detailView['Description']"></div>
          </Paragraph>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import TitleComponent from "@/components/TitleComponent";
import Paragraph from "@/components/Paragraph";

export default {
  metaInfo() {
    return {
      title: `${
        this.$route.params.category
          ? this.categoryRouteMap[this.$route.params.category]
          : ""
      } Store`,
      titleTemplate: `%s | Safety Management Services, Inc.`,
      meta: [
        {
          name: "description",
          content: `${
            this.$route.params.category
              ? this.descriptionRouteMap[this.$route.params.category]
              : "All of SMS's products."
          }`
        }
      ]
    };
  },
  components: {
    TitleComponent,
    Paragraph
  },
  data: () => {
    return {
      selectedCategory: "",
      selectedCategoryMap: {},
      detailView: null,
      detailImageIndex: 0,
      detailCartAddAmount: 1,
      selectedOption: "",
      latestAdd: null,
      hide: false,
      catalog: [
        {
          ID: 4112,
          Type: "simple",
          SKU: "001",
          Name: "ABL Friction Plates",
          Price: "Call for price",
          Show: 1,
          "Is featured?": 1,
          "Visibility in catalog": "visible",
          "Tax status": "taxable",
          "In stock?": 1,
          Categories: "ABL Friction Parts",
          Images: [
            "plate-min.png",
            "plate-side-min.png",
            "wheel-plate-min.png",
            "wheel-plate-side-min.png"
          ]
        },
        {
          ID: 8112,
          Type: "simple",
          SKU: "115",
          Name: "Koenen Tube",
          Show: 1,
          "Is featured?": 1,
          "Visibility in catalog": "visible",
          "Tax status": "taxable",
          "In stock?": 1,
          "Regular price": 60,
          Categories: "Koenen Tubes",
          "Shipping class": "koenen tubes",
          Images: ["koenen-1.jpg", "koenen-2.jpg", "koenen-3.jpg"]
        },
        {
          ID: 4114,
          Type: "simple",
          SKU: "002",
          Name: "Impact Anvils",
          Price: "Call for price",
          Show: 1,
          "Is featured?": 1,
          "Visibility in catalog": "visible",
          "Tax status": "none",
          "In stock?": 1,
          "Allow customer reviews?": 1,
          Categories: "MBOM/Type 12 Impact Parts",
          Images: ["Type12Anvil_I_small2-min.png"]
        },
        {
          ID: 4115,
          Type: "simple",
          SKU: "003",
          Name: "ABL Friction Wheels",
          Price: "Call for price",
          Show: 1,
          "Is featured?": 1,
          "Visibility in catalog": "visible",
          "Tax status": "none",
          "In stock?": 1,
          Categories: "ABL Friction Parts",
          Images: [
            "wheel-min.png",
            "wheel-side-min.png",
            "wheel-plate-min.png",
            "wheel-plate-side-min.png"
          ]
        },
        {
          ID: 4116,
          Type: "simple",
          SKU: "PA-050",
          Name: 'DOT-SP13481 Shipping Container (4")',
          Show: 1,
          "Is featured?": 1,
          "Visibility in catalog": "visible",
          "Tax status": "none",
          "In stock?": 1,
          "Regular price": 2050,
          Categories: "Shipping Containers",
          "Shipping class": '4" Liquid Shipping Pipe',
          Images: ["4inLiquid_I_small2-min.png"]
        },
        {
          ID: 4117,
          Type: "simple",
          SKU: "PA-020",
          Name: 'DOT-SP 8451 Shipping Container (4")',
          Show: 1,
          "Is featured?": 1,
          "Visibility in catalog": "visible",
          "Short description":
            "Each shipping pipe consists of the following:<ul><li>Schedule 80 seamless steel pipe nipple</li><li>Either 6” diameter × 14” long nipple or 4” diameter × 14” long nipple</li><li>3,000-psi end caps (ASTM 105)</li><li>Threads cut so that the end caps can be screwed on a minimum of 5 threads by hand</li><li>Shipping pipes are compliant with DOT SP 8451 requirements</li></ul>Each UN 4D Plywood box consists of the following:<ul><li>Durable 0.75” thick plywood</li><li>Retracting steel handles</li><li>Foam cushioned pipe supports</li><li>Removable top lid</li><li>Certified to UN Non-bulk Performance-oriented Packaging (POP) Standards</li></ul>",
          Description:
            "Each container consists of a Schedule 80 seamless pipe and 3,000-psi end caps. The complete shipping container includes a UN Non-bulk Performance-oriented Packaging (POP) tested 4D plywood box. The plywood box is equipped with retracting handles for easy lifting and foam supports to support the pipe. Internal packaging is not included.",
          "Tax status": "none",
          "In stock?": 1,
          "Regular price": 1150,
          Categories: "Shipping Containers",
          "Shipping class": '4" Complete Shipping Container',
          Images: [
            "4inComplete_I_small2-min.png",
            "4inPipe_I_small2-min.png",
            "4inBox_I_small2-min.png"
          ]
        },
        {
          ID: 4118,
          Type: "simple",
          SKU: "PA-040",
          Name: 'DOT-SP 8451 Shipping Container (6")',
          Show: 1,
          "Is featured?": 1,
          "Visibility in catalog": "visible",
          "Short description":
            "Each shipping pipe consists of the following:<ul><li>Schedule 80 seamless steel pipe nipple</li><li>Either 6” diameter × 14” long nipple or 4” diameter × 14” long nipple</li><li>3,000-psi end caps (ASTM 105)</li><li>Threads cut so that the end caps can be screwed on a minimum of 5 threads by hand</li><li>Shipping pipes are compliant with DOT SP 8451 requirements</li></ul>Each UN 4D Plywood box consists of the following:<ul><li>Durable 0.75” thick plywood</li><li>Retracting steel handles</li><li>Foam cushioned pipe supports</li><li>Removable top lid</li><li>Certified to UN Non-bulk Performance-oriented Packaging (POP) Standards</li></ul>",
          Description:
            "Each container consists of a Schedule 80 seamless pipe and 3,000-psi end caps. The complete shipping container includes a UN Non-bulk Performance-oriented Packaging (POP) tested 4D plywood box. The plywood box is equipped with retracting handles for easy lifting and foam supports to support the pipe. Internal packaging is not included.",
          "Tax status": "none",
          "In stock?": 1,
          "Regular price": 1975,
          Categories: "Shipping Containers",
          "Shipping class": '6" Complete Shipping Container',
          Images: [
            "6inComplete_I_small2-min.png",
            "6inBox_I_small2-min.png",
            "6inPipe_I_small2-min.png"
          ]
        },
        {
          ID: 4124,
          Type: "simple",
          SKU: "007",
          Name: "Impact Inserts",
          Price: "Call for price",
          Show: 1,
          "Is featured?": 1,
          "Visibility in catalog": "visible",
          "Tax status": "none",
          "In stock?": 1,
          Categories: "MBOM/Type 12 Impact Parts",
          Images: [
            "Type12Insert_I_small2-min.png",
            "Type12insertanvil-min.png",
            "MBOMinsertanvil-min.png"
          ]
        },

        {
          ID: 13045,
          Type: "variable",
          SKU: "CPSPATULA",
          Name: "Conductive Plastic Spatula",
          Price: "$25.00 – $30.00",
          Show: 1,
          "Is featured?": 1,
          "Visibility in catalog": "visible",
          "Short description":
            "These conductive polypropylene spatulas are designed to safely and effectively scrape and lift material from all types of surfaces.  The spatula material is non-metallic and chemical resistant.  The beveled edge and ergonomic handle allow for maximum efficiency, and minimum friction when in use. <strong>Minimum 5 per order.</strong>",
          "Tax status": "taxable",
          "In stock?": 1,
          Categories: "Lo-Ohm Conductive Plastic Tools",
          "Shipping class": "Small Conductive Tools",
          Images: ["SMSC82904-min.png"],
          Items: [
            // HERE
            {
              ID: 13046,
              Type: "variation",
              SKU: "SMSC82904",
              Name: 'Conductive Plastic Spatula - 2.95" x 10',
              Published: 1,
              "Visibility in catalog": "visible",
              "Tax status": "taxable",
              "Tax class": "parent",
              "In stock?": 1,
              "Weight (lbs)": 0.2,
              "Length (in)": 10,
              "Width (in)": 2.95,
              "Regular price": 25.0,
              "Shipping class": "with-conditions",
              Images: ["SMSC82904-min.png"],
              Parent: "CPSPATULA",
              Position: 1,
              "Attribute 1 name": "Size",
              "Attribute 1 value(s)": '2.95" x 10'
            },
            // HERE
            {
              ID: 13047,
              Type: "variation",
              SKU: "SMSC15109",
              Name: 'Conductive Plastic Spatula - 4.3" x 10',
              Published: 1,
              "Visibility in catalog": "visible",
              "Tax status": "taxable",
              "Tax class": "parent",
              "In stock?": 1,
              "Weight (lbs)": 0.2,
              "Length (in)": 10,
              "Width (in)": 4.3,
              "Regular price": 30.0,
              "Shipping class": "with-conditions",
              Images: ["SMSC15109-min.png"],
              Parent: "CPSPATULA",
              Position: 2,
              "Attribute 1 name": "Size",
              "Attribute 1 value(s)": '4.3" x 10'
            }
          ],
          "Attribute 1 name": "Size",
          "Attribute 1 value(s)": '2.95" x 10", 4.3" x 10", 3.9" x 9.5',
          "Attribute 1 visible": 1
        },
        {
          ID: 13077,
          Type: "variable",
          SKU: "CPSCOOP",
          Name: "Conductive Plastic Scoop",
          Price: "$36.00 – $50.00",
          Show: 1,
          "Is featured?": 1,
          "Visibility in catalog": "visible",
          "Short description":
            "These durable, lightweight scoops come in three sizes, and are ideal for functional use in a variety of settings.  The ergonomic grip handle ensures efficient and comfortable scooping, and the conductive polypropylene is continuous with no cracks or seams, eliminating the opportunity for energetic material to be lodged or hidden. <strong>Minimum 5 per order.</strong>",
          "Tax status": "taxable",
          "In stock?": 1,
          Categories: "Lo-Ohm Conductive Plastic Tools",
          "Shipping class": "Small Conductive Tools",
          Images: ["Scoops-min.png"],
          Items: [
            //HERE
            {
              ID: 13085,
              Type: "variation",
              SKU: "SMSC15105-1",
              Name: 'Conductive Plastic Scoop - 4.3" × 5.9" × 10.4',
              Published: 1,
              "Visibility in catalog": "visible",
              "Tax status": "taxable",
              "Tax class": "parent",
              "In stock?": 1,
              "Length (in)": 4.3,
              "Width (in)": 5.9,
              "Height (in)": 10.4,
              "Regular price": 36.0,
              "Shipping class": "with-conditions",
              Images: ["SMSC15105-min.png"],
              Parent: "CPSCOOP",
              Position: 1,
              "Attribute 1 name": "Size",
              "Attribute 1 value(s)": '4.3" × 5.9" × 10.4'
            },
            //HERE
            {
              ID: 13086,
              Type: "variation",
              SKU: "SMSC15106-1",
              Name: 'Conductive Plastic Scoop - 5.3" × 7.3" × 12.2',
              Published: 1,
              "Visibility in catalog": "visible",
              "Tax status": "taxable",
              "Tax class": "parent",
              "In stock?": 1,
              "Length (in)": 5.3,
              "Width (in)": 7.3,
              "Height (in)": 12.2,
              "Regular price": 40.0,
              "Shipping class": "with-conditions",
              Images: ["SMSC15106-min.png"],
              Parent: "CPSCOOP",
              Position: 2,
              "Attribute 1 name": "Size",
              "Attribute 1 value(s)": '5.3" × 7.3" × 12.2'
            },
            //HERE
            {
              ID: 13087,
              Type: "variation",
              SKU: "SMSC15107-1",
              Name: 'Conductive Plastic Scoop - 6.3" × 15',
              Published: 1,
              "Visibility in catalog": "visible",
              "Tax status": "taxable",
              "Tax class": "parent",
              "In stock?": 1,
              "Length (in)": 6.3,
              "Width (in)": 15,
              "Regular price": 50.0,
              "Shipping class": "with-conditions",
              Images: ["SMSC15107-min.png"],
              Parent: "CPSCOOP",
              Position: 3,
              "Attribute 1 name": "Size",
              "Attribute 1 value(s)": '6.3" × 15'
            }
          ],
          "Attribute 1 name": "Size",
          "Attribute 1 value(s)":
            '4.3" × 5.9" × 10.4", 5.3" × 7.3" × 12.2", 6.3" × 15',
          "Attribute 1 visible": 1
        },
        {
          ID: 13093,
          Type: "variable",
          SKU: "CPSCRAPER-1",
          Name: "Conductive Plastic Scraper",
          Price: "$10.00",
          Show: 1,
          "Is featured?": 1,
          "Visibility in catalog": "visible",
          "Short description":
            "These conductive plastic dough scrapers are designed to safely remove material from a variety of surfaces. The scrapers are flexible with beveling on both flat and rounded edge, making them ideal for use on all contours and surfaces.<strong>Minimum 10 per order.</strong>",
          "Tax status": "taxable",
          "In stock?": 1,
          Categories: "Lo-Ohm Conductive Plastic Tools",
          "Shipping class": "Small Conductive Tools",
          Images: ["Scrapers-min.png"],
          Items: [
            //HERE
            {
              ID: 13097,
              Type: "variation",
              SKU: "SMSC81916",
              Name: 'Conductive Plastic Scraper - 6.3" × 4.9',
              Published: 1,
              "Visibility in catalog": "visible",
              "Tax status": "taxable",
              "Tax class": "parent",
              "In stock?": 1,
              "Length (in)": 6.3,
              "Width (in)": 4.9,
              "Regular price": 10.0,
              "Shipping class": "with-conditions",
              Images: ["SMSC81916-min.png"],
              Parent: "CPSCRAPER-1",
              Position: 1,
              "Attribute 1 name": "Size",
              "Attribute 1 value(s)": '6.3" × 4.9'
            },
            //HERE
            {
              ID: 13098,
              Type: "variation",
              SKU: "SMSC81911",
              Name: 'Conductive Plastic Scraper - 5.75" × 3.85',
              Show: 1,
              "Visibility in catalog": "visible",
              "Tax status": "taxable",
              "Tax class": "parent",
              "In stock?": 1,
              "Length (in)": 5.75,
              "Width (in)": 3.85,
              "Regular price": 10.0,
              "Shipping class": "with-conditions",
              Images: ["SMSC81911-min.png"],
              Parent: "CPSCRAPER-1",
              Position: 2,
              "Attribute 1 name": "Size",
              "Attribute 1 value(s)": '5.75" × 3.85'
            }
          ],
          "Attribute 1 name": "Size",
          "Attribute 1 value(s)": '6.3" × 4.9", 5.75" × 3.85',
          "Attribute 1 visible": 1
        },
        {
          ID: 13102,
          Type: "variable",
          SKU: "CPBRUSH-1",
          Name: "Conductive Plastic Flat Brush",
          Price: "$32.00 - $42.00",
          Show: 1,
          "Is featured?": 1,
          "Visibility in catalog": "visible",
          "Short description":
            "Conductive brushes enable a through cleaning of many types of surfaces. Conventional brushes generate high charges that endanger the products and the employees, SMS conductive soft brushes consist of a conductive plastic handle and bristles composed of small-diametric conductive fibers (Thunderon) mixed with natural bristles that provide the perfect stiffness.<strong>Minimum 5 per order.</strong>",
          "Tax status": "taxable",
          "In stock?": 1,
          Categories: "Lo-Ohm Conductive Plastic Tools",
          "Shipping class": "Small Conductive Tools",
          Images: ["SMSC1001-min.png"],
          Items: [
            //HERE
            {
              ID: 13106,
              Type: "variation",
              SKU: "SMSC1000",
              Name:
                'Conductive Plastic Flat Brush - 4" Soft Flat Brush - Dissipative Bristles',
              Published: 1,
              "Visibility in catalog": "visible",
              "Tax status": "taxable",
              "Tax class": "parent",
              "In stock?": 1,
              "Regular price": 32.0,
              "Shipping class": "with-conditions",
              Images: ["SMSC1000-min.png"],
              Parent: "CPBRUSH-1",
              Position: 1,
              "Attribute 1 name": "Type",
              "Attribute 1 value(s)":
                '4" Soft Flat Brush - Dissipative Bristles'
            },
            //HERE
            {
              ID: 13107,
              Type: "variation",
              SKU: "SMSC1001",
              Name:
                'Conductive Plastic Flat Brush - 6" Drawing Soft Flat Brush - Dissipative Bristles',
              Published: 1,
              "Visibility in catalog": "visible",
              "Tax status": "taxable",
              "Tax class": "parent",
              "In stock?": 1,
              "Regular price": 42.0,
              "Shipping class": "with-conditions",
              Images: ["SMSC1001-min.png"],
              Parent: "CPBRUSH-1",
              Position: 2,
              "Attribute 1 name": "Type",
              "Attribute 1 value(s)":
                '6" Drawing Soft Flat Brush - Dissipative Bristles'
            }
          ],
          "Attribute 1 name": "Type",
          "Attribute 1 value(s)":
            '4" Soft Flat Brush - Dissipative Bristles, 6" Drawing Soft Flat Brush - Dissipative Bristles',
          "Attribute 1 visible": 1
        },
        {
          ID: 13272,
          Type: "simple",
          SKU: "SMSC28290",
          Name: "Conductive Paddle Scraper",
          Show: 1,
          "Is featured?": 1,
          "Visibility in catalog": "visible",
          "Short description":
            'Conductive Paddle Scraper 4.4" × 9.25" (Base Only)**<em>**Handles SMS29903/SMS29904 Sold Separately</em>',
          "Tax status": "taxable",
          "In stock?": 1,
          "Regular price": 50.0,
          Categories: "Lo-Ohm Conductive Plastic Tools",
          "Shipping class": "with-conditions",
          Images: ["SMS28290-L-min.png"]
        },
        {
          ID: 13276,
          Type: "variable",
          SKU: "CPSHOVEL-1",
          Name: "Conductive Shovel",
          Price: "$200.00 – $215.00",
          Show: 1,
          "Is featured?": 1,
          "Visibility in catalog": "visible",
          "Tax status": "taxable",
          "In stock?": 1,
          Categories: "Lo-Ohm Conductive Plastic Tools",
          "Shipping class": "Large Conductive Tools",
          Images: ["SMS14104-L-min.png"],
          Items: [
            //HERE
            {
              ID: 13277,
              Type: "variation",
              SKU: "SMSC14104",
              Name: 'Conductive Shovel - 13" × 15" × 44',
              Published: 1,
              "Visibility in catalog": "visible",
              "Tax status": "taxable",
              "Tax class": "parent",
              "In stock?": 1,
              "Length (in)": 15,
              "Width (in)": 13,
              "Height (in)": 44,
              "Regular price": 215.0,
              "Shipping class": "Larger Conductive Tools",
              Images: ["SMS14104-L-min.png"],
              Parent: "CPSHOVEL-1",
              Position: 1,
              "Attribute 1 name": "Size",
              "Attribute 1 value(s)": '13" × 15" × 44'
            },
            // HERE
            {
              ID: 13278,
              Type: "variation",
              SKU: "SMSC15103",
              Name: 'Conductive Shovel - 10.6" × 13.3" × 52',
              Published: 1,
              "Visibility in catalog": "visible",
              "Tax status": "taxable",
              "Tax class": "parent",
              "In stock?": 1,
              "Length (in)": 13.3,
              "Width (in)": 10.6,
              "Height (in)": 52,
              "Regular price": 200.0,
              "Shipping class": "Larger Conductive Tools",
              Images: ["SMS15103-L-min.png"],
              Parent: "CPSHOVEL-1",
              Position: 2,
              "Attribute 1 name": "Size",
              "Attribute 1 value(s)": '10.6" × 13.3" × 52'
            }
          ],
          "Attribute 1 name": "Size",
          "Attribute 1 value(s)": '13" × 15" × 44", 10.6" × 13.3" × 52',
          "Attribute 1 visible": 1
        },
        {
          ID: 13283,
          Type: "variable",
          SKU: "CPBRMDSTPN-1",
          Name: "Conductive Brooms and Dust Pans",
          Price: "$30.00 – $160.00",
          Show: 1,
          "Is featured?": 1,
          "Visibility in catalog": "visible",
          "Tax status": "taxable",
          "In stock?": 1,
          Categories: "Lo-Ohm Conductive Plastic Tools",
          "Shipping class": "Large Conductive Tools",
          Images: ["SMS80500-L-min.png"],
          Items: [
            {
              ID: 13311,
              Type: "variation",
              SKU: "SMSC80500",
              Name:
                "Conductive Brooms and Dust Pans - Broom and Dust Pan Combo",
              Published: 1,
              "Visibility in catalog": "visible",
              "Tax status": "taxable",
              "Tax class": "parent",
              "In stock?": 1,
              "Regular price": 120.0,
              "Shipping class": "with-conditions",
              Images: ["SMS80500-L-min.png"],
              Parent: "CPBRMDSTPN-1",
              Position: 1,
              "Attribute 1 name": "Type",
              "Attribute 1 value(s)": "Broom and Dust Pan Combo"
            },
            {
              ID: 13312,
              Type: "variation",
              SKU: "SMSC80301",
              Name:
                "Conductive Brooms and Dust Pans - Large Dustpan (No Broom)",
              Published: 1,
              "Visibility in catalog": "visible",
              "Tax status": "taxable",
              "Tax class": "parent",
              "In stock?": 1,
              "Length (in)": 11.8,
              "Width (in)": 12.2,
              "Regular price": 30.0,
              "Shipping class": "with-conditions",
              Images: ["SMS80301-L-min.png"],
              Parent: "CPBRMDSTPN-1",
              Position: 2,
              "Attribute 1 name": "Type",
              "Attribute 1 value(s)": "Large Dustpan (No Broom)"
            },
            {
              ID: 13313,
              Type: "variation",
              SKU: "SMSC25155",
              Name:
                'Conductive Brooms and Dust Pans - Conductive Broom 15.75" × 2.35" (Base Only)',
              Published: 1,
              "Visibility in catalog": "visible",
              "Tax status": "taxable",
              "Tax class": "parent",
              "In stock?": 1,
              "Length (in)": 2.35,
              "Width (in)": 15.75,
              "Regular price": 130.0,
              "Shipping class": "Large Conductive Tools",
              Images: ["SMS25155-L-min.png"],
              Parent: "CPBRMDSTPN-1",
              Position: 3,
              "Attribute 1 name": "Type",
              "Attribute 1 value(s)":
                'Conductive Broom 15.75" × 2.35" (Base Only)'
            },
            //here
            {
              ID: 13314,
              Type: "variation",
              SKU: "SMSC26155",
              Name:
                'Conductive Brooms and Dust Pans - Conductive Broom 19.5" × 2.35" (Base Only)',
              Published: 1,
              "Visibility in catalog": "visible",
              "Tax status": "taxable",
              "Tax class": "parent",
              "In stock?": 1,
              "Length (in)": 2.35,
              "Width (in)": 19.5,
              "Regular price": 160.0,
              "Shipping class": "Large Conductive Tools",
              Images: ["SMS26155-L-min.png"],
              Parent: "CPBRMDSTPN-1",
              Position: 4,
              "Attribute 1 name": "Type",
              "Attribute 1 value(s)":
                'Conductive Broom 19.5" × 2.35" (Base Only)'
            }
          ],
          "Attribute 1 name": "Type",
          "Attribute 1 value(s)":
            'Broom and Dust Pan Combo, Large Dustpan (No Broom), Conductive Broom 15.75" × 2.35" (Base Only), Conductive Broom 19.5" × 2.35" (Base Only)',
          "Attribute 1 visible": 1
        },
        {
          ID: 13295,
          Type: "simple",
          SKU: "SMSC28500",
          Name: "Conductive Squeegee",
          Show: 1,
          "Is featured?": 1,
          "Visibility in catalog": "visible",
          "Short description":
            'Conductive Squeegee 20" Length (Base Only)**<em>**Handles SMS29903/SMS29904 Sold Separately</em>',
          "Tax status": "taxable",
          "In stock?": 1,
          "Regular price": 100.0,
          Categories: "Lo-Ohm Conductive Plastic Tools",
          "Shipping class": "Large Conductive Tools",
          Images: ["SMS28500-L-min.png"]
        },
        {
          ID: 13297,
          Type: "simple",
          SKU: "SMSC54134",
          Name: "Conductive Round Brush",
          Show: 1,
          "Is featured?": 1,
          "Visibility in catalog": "visible",
          "Short description": "Conductive Round Brush Ø4.9",
          "Tax status": "taxable",
          "In stock?": 1,
          "Regular price": 65.0,
          Categories: "Lo-Ohm Conductive Plastic Tools",
          "Shipping class": "with-conditions",
          Images: ["SMS54134-L-min.png"]
        },
        {
          ID: 13299,
          Type: "simple",
          SKU: "SMSC15064",
          Name: "Conductive Hand Scrub Brush",
          Show: 1,
          "Is featured?": 1,
          "Visibility in catalog": "visible",
          "Short description": 'Conductive Hand Scrub Brush 8.3" × 2.75',
          "Tax status": "taxable",
          "In stock?": 1,
          "Regular price": 90.0,
          Categories: "Lo-Ohm Conductive Plastic Tools",
          "Shipping class": "with-conditions",
          Images: ["SMS15064-L-min.png"]
        },
        {
          ID: 13305,
          Type: "simple",
          SKU: "SMSC10252",
          Name: "Conductive Bannister Brush",
          Show: 1,
          "Is featured?": 1,
          "Visibility in catalog": "visible",
          "Short description": 'Conductive Bannister Brush 13" × 1.4',
          "Tax status": "taxable",
          "In stock?": 1,
          "Regular price": 62.0,
          Categories: "Lo-Ohm Conductive Plastic Tools",
          "Shipping class": "with-conditions",
          Images: ["SMS10252-L-min.png"]
        },
        {
          ID: 13315,
          Type: "variable",
          SKU: "CPSHOVEL-1-1",
          Name: "Conductive Buckets and Lids",
          Price: "$23.00 – $110.00",
          Show: 1,
          "Is featured?": 1,
          "Visibility in catalog": "visible",
          "Tax status": "taxable",
          "In stock?": 1,
          Categories: "Lo-Ohm Conductive Plastic Tools",
          "Shipping class": "with-conditions-buckets",
          Images: ["SMS80101-L-min.png"],
          Items: [
            {
              ID: 13316,
              Type: "variation",
              SKU: "SMSC80102",
              Name: "Conductive Buckets and Lids - Conductive Bucket 2 Gallon",
              Published: 1,
              "Visibility in catalog": "visible",
              "Shipping class": "with-conditions-buckets",
              "Tax status": "taxable",
              "Tax class": "parent",
              "In stock?": 1,
              "Regular price": 85.0,
              Images: ["2GalBucket.png"],
              Parent: "CPSHOVEL-1-1",
              Position: 1,
              "Attribute 1 name": "Type",
              "Attribute 1 value(s)": "Conductive Bucket 2 Gallon"
            },

            {
              ID: 13317,
              Type: "variation",
              SKU: "SMSC80112",
              Name:
                "Conductive Buckets and Lids - Conductive Lid for Bucket 2 Gallon",
              Published: 1,
              "Visibility in catalog": "visible",
              "Shipping class": "with-conditions-buckets",
              "Tax status": "taxable",
              "Tax class": "parent",
              "In stock?": 1,
              "Regular price": 23.0,
              Images: ["2GalLid.png"],
              Parent: "CPSHOVEL-1-1",
              Position: 2,
              "Attribute 1 name": "Type",
              "Attribute 1 value(s)": "Conductive Lid for Bucket 2 Gallon"
            },

            //Here
            {
              ID: 13318,
              Type: "variation",
              SKU: "SMSC80101",
              Name: "Conductive Buckets and Lids - Conductive Bucket 4 Gallon",
              Published: 1,
              "Visibility in catalog": "visible",
              "Shipping class": "with-conditions-buckets",
              "Tax status": "taxable",
              "Tax class": "parent",
              "In stock?": 1,
              "Regular price": 110.0,
              Images: ["SMS80101-L-min.png"],
              Parent: "CPSHOVEL-1-1",
              Position: 1,
              "Attribute 1 name": "Type",
              "Attribute 1 value(s)": "Conductive Bucket 4 Gallon"
            },
            //Here
            {
              ID: 13319,
              Type: "variation",
              SKU: "SMSC80111",
              Name:
                "Conductive Buckets and Lids - Conductive Lid for Bucket 4 Gallon",
              Published: 1,
              "Visibility in catalog": "visible",
              "Shipping class": "with-conditions-buckets",
              "Tax status": "taxable",
              "Tax class": "parent",
              "In stock?": 1,
              "Regular price": 40.0,
              Images: ["SMS80111-L-min.png"],
              Parent: "CPSHOVEL-1-1",
              Position: 2,
              "Attribute 1 name": "Type",
              "Attribute 1 value(s)": "Conductive Lid for Bucket 4 Gallon"
            }
          ],
          "Attribute 1 name": "Type",
          "Attribute 1 value(s)":
            "Conductive Bucket 4 Gallon, Conductive Lid for Bucket 4 Gallon",
          "Attribute 1 visible": 1
        }
      ],
      categoryRouteMap: {
        containers: "Shipping Containers",
        conductive: "Lo-Ohm Conductive Plastic Tools",
        koenen: "Koenen Tubes",
        abl: "ABL Friction Parts",
        mbom: "MBOM/Type 12 Impact Parts"
      },
      descriptionRouteMap: {
        containers: "DOT-SP 8451 and DOT-SP13481 shipping containers for sale.",
        conductive:
          "Plastic tools designed to minimize ignition hazards during handling and clean-up.",
        koenen: "Koenen Tubes for UN Manual of Tests and Criteria",
        abl: "Replacement parts for the SMS ABL friction machine.",
        mbom: "Replacement parts for the SMS MBOM/Type 12 impact machine."
      }
    };
  },
  beforeMount() {
    if (this && this.$route.params.category) {
      this.selectedCategory =
        this.categoryRouteMap[this.$route.params.category] || "";
    }
    this.$router.beforeEach((to, from, next) => {
      this.selectedCategory = this.categoryRouteMap[to.params.category] || "";
      next();
    });
  },
  methods: {
    addToCart(item) {
      this.latestAdd = {
        name: item.Name,
        amount: this.detailCartAddAmount
      };
      this.$store.dispatch("cart/addToCart", {
        item: item,
        count: this.detailCartAddAmount
      });
      //this.$store
      this.hide = true;
      setTimeout(() => {
        window.scrollTo(0, 0);
        this.detailCartAddAmount = 1;
        this.hide = false;
      }, 400);
      //add to cart/store
    },
    changeAmount(indicator) {
      if (indicator === "add") {
        this.detailCartAddAmount += 1;
      } else if (this.detailCartAddAmount > 1) {
        this.detailCartAddAmount -= 1;
      }
    },
    updateCategory() {
      this.$router.push({
        path: `/shop/${event.currentTarget.dataset.category}`
      });
    },
    setDetailView(item) {
      this.detailView = item;
    }
  },
  computed: {
    catalogList: function() {
      // `this` points to the vm instance
      return this.catalog.filter(item => {
        if (
          this.selectedCategory &&
          item.Categories !== this.selectedCategory
        ) {
          return false;
        }
        return true;
      });
    }
  }
};
</script>

<style lang="scss">
.store {
  .link {
    cursor: pointer;
    color: #aa222c;
  }
  .grey {
    font-weight: bold;
    color: grey;
    font-size: 14px;
  }
  .fade-enter-active,
  .fade-leave-active {
    transition: opacity 0.5s;
  }
  .fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
    opacity: 0;
  }
}
</style>
